import { useState, useEffect } from 'react'
import { Grid, TextField } from '@mui/material';
import { Wrapper } from "@googlemaps/react-wrapper";
import { Map, Marker } from '../Map/Map';
import { GOOGLE_MAPS_API } from '../../settings/config';
import InputFieldsContext from '../../context/input_fields/context';

export function AddressField({ field, field_location }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [clicks, setClicks] = useState([]);
    const [addressText, setAddressText] = useState();
    const [zoom, setZoom] = useState(3);
    const [runGetLoc, setRunGetLoc] = useState(true);
    const [center, setCenter] = useState({
        lat: 0,
        lng: 0,
    });

    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const handleChange = (formated_address, latLong) => {

        const fieldName = field.field_state_name;

        setAddressText(formated_address);
        updateInputFields({[fieldName]: formated_address+" "+latLong});
        if (field.required) {
            updateErrors({[fieldName]: false});
        }
    };

    const onClick = (e) => {
        // avoid directly mutating state
        setClicks([e.latLng]);
        // Se rellena el input con la dirección y LatLong del marcador
        var geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
            'latLng': e.latLng
        }, function(results, status) {
            if (status === window.google.maps.GeocoderStatus.OK) {
                if (results[0]) {
                    handleChange(results[0].formatted_address, e.latLng);
                }
            }
        });
    };

    const onIdle = (m) => {

        setZoom(m.getZoom());
        setCenter(m.getCenter().toJSON());

        if (field_location !== undefined && runGetLoc) {
            setRunGetLoc(false);
            // Obtenemos el dirección, latitud y lóngitud proporcionada por el admin y la mostramos
            const address = field_location.split(" (")[0];
            let temp = field_location.match(/([^(,)]+)(?!.*\()/g);
            const lat = parseFloat(temp[0]);
            const lng = parseFloat(temp[1]);

            const location = new window.google.maps.LatLng(lat, lng);

            setCenter({
                lat: lat,
                lng: lng
            });
            setZoom(13);
            setClicks([location]);
            handleChange(address, location);
        }
        
    };

    return (
        <Grid item xs={12} md={12}>
            <Wrapper apiKey={GOOGLE_MAPS_API}>
                <Map
                    center={center}
                    zoom={zoom}
                    onClick={(!('location' in field)) && onClick}
                    onIdle={onIdle}
                    style={{ flexGrow: "1", height: "500px" }}
                >
                    {clicks.map((latLng, i) => (
                        <Marker key={i} position={latLng} />
                    ))}
                </Map>
            </Wrapper>
            <TextField 
                fullWidth
                disabled
                className="margin-text-field border-radius-30"
                id="outlined-basic" 
                value={addressText || ''}
                variant="outlined" 
            />
        </Grid>
    )
}