import { ToggleButtonGroup, styled } from '@mui/material';

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        width: "100%",
        backgroundColor: "var(--calendar-button-color)",
        '&.Mui-selected': {
            backgroundColor: "var(--secondary-color)",
            color: "white",
        },
        '&.Mui-selected:hover': {
            backgroundColor: "var(--secondary-color)",
        },
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: "10px",
            marginTop: "20px"
        },
        '&:first-of-type': {
            borderRadius: "10px",
        },
    },
}));