
export class GetForm {
    
    constructor(){
        this.relative_url = "send_form/"
        this.method = "get"
    }
}

export class SendResponse {
    
    constructor(){
        this.relative_url = "receive_response/"
        this.method = "post"
    }
}

export class AvailableHours {
    
    constructor(){
        this.relative_url = "available_hours/<question>/<day>"
        this.method = "get"
    }

    setRelativeUrlParameters(relative_data){
        const data_arr = Object.entries(relative_data);
        
        data_arr.forEach((element) =>
            this.relative_url = this.relative_url.replace('<' + element[0] + '>', element[1])
        )
    }
}

export class GetFormResponse {
    
    constructor(){
        this.relative_url = "send_form_response/<appointment>"
        this.method = "get"
    }

    setRelativeUrlParameters(relative_data){
        const data_arr = Object.entries(relative_data);
        
        data_arr.forEach((element) =>
            this.relative_url = this.relative_url.replace('<' + element[0] + '>', element[1])
        )
    }
}