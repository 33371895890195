import { useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import InputFieldsContext from '../../context/input_fields/context';

export function DateField({ field, size }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();

    // Incluimos el campo a la lista de campos con posibilida de errores
    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const handleChange = (value) => {
        const fieldName = field.field_state_name;
        const year = value.$y.toString();
        const month = value.$M+1<10 ? "0"+(value.$M+1).toString() : (value.$M+1).toString();
        const day = value.$D<10 ? "0"+value.$D.toString() : value.$D.toString();
        
        updateInputFields({[fieldName]: year+"-"+month+"-"+day});
        if (field.required) {
            updateErrors({[fieldName]: false});
        }
    };

    return (
      <Grid item xs={12} md={size}>
          <h4 className="date-label">{field.field_name}{field.required && " *"}</h4>
          <Grid container>
              <Grid item xs={12} md={12} className="date-picker-style">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <StaticDatePicker
                            disablePast
                            displayStaticWrapperAs="desktop"
                            id="outlined-basic" 
                            value={dayjs(formState.inputs[field.field_state_name]) || ''}
                            onChange={handleChange}
                            renderInput={(params) => <TextField required={field.required} {...params} />}
                      />
                  </LocalizationProvider>
              </Grid>
          </Grid>
      </Grid>
    )
}