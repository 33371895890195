import { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Checkbox, Grid } from '@mui/material';
import InputFieldsContext from '../../context/input_fields/context';

export function BooleanField({ field, size }) {

    const boolValue = useRef('')

    const [isChecked, setIsChecked] = useState(false)

    const { state:formState, updateInputFields, setFirstTimeVisitor } = InputFieldsContext.UseContext();

    // El valor inicial de los BooleanField es false
    useEffect(() => {
        const fieldName = field.field_state_name;
        if (boolValue.current.value){
            updateInputFields({[fieldName]: true});
            setIsChecked(true)
        }
        if (formState.inputs[field.field_state_name] === undefined) {
            updateInputFields({[fieldName]: false});
            setIsChecked(false)
        }

    }, [])

    const handleChange = (elem) => {
        const { checked } = elem.target;
        const fieldName = field.field_state_name;
        
        updateInputFields({[fieldName]: checked});
        setIsChecked(!isChecked)

        if (field.is_first_time_visitor){
            setFirstTimeVisitor(checked)
        }
    };

    return (
        <Grid item xs={12} md={size}>
            <FormControlLabel 
                label={field.field_name}
                className="margin-top-boolean"
                control={
                    <Checkbox 
                        required={field.required}
                        onChange={handleChange}
                        value={formState.inputs[field.field_state_name] || ''}
                        inputRef={boolValue}
                        checked={isChecked}
                    />
                } 
            />
        </Grid>
    )
}