// Si deseas cambiar el entorno, modifica esto a 'PROD'
const SELECTED_ENVIRONTMENT = "PROD";


const DEV_DOMAIN="http://localhost:8000/appointments/"
const STAGING_DOMAIN='https://staging.imoc.ubicutus.com/appointments/'
const PROD_DOMAIN="https://imoc.com.ve/appointments/"

var DOMAIN=''

if(String(SELECTED_ENVIRONTMENT)==="DEV"){
    DOMAIN=DEV_DOMAIN
}

else if(String(SELECTED_ENVIRONTMENT)==="STAGING"){
    DOMAIN=STAGING_DOMAIN
}

else if(String(SELECTED_ENVIRONTMENT)==="PROD"){
    DOMAIN=PROD_DOMAIN
}

var FORM_IMAGE_URL = "/logo_imoc.png" 
var GOOGLE_MAPS_API = "EMPTY"

export {DOMAIN, GOOGLE_MAPS_API, FORM_IMAGE_URL};