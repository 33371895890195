import { GetForm, SendResponse, AvailableHours, GetFormResponse } from "./endpoint";

class Service {
    
    constructor() {
        this.endpoints = {
            "get-form": GetForm,
            "send-response": SendResponse,
            "available-hours": AvailableHours,
            "get-form-response": GetFormResponse,
        };
    }
}

const UbiAppointmentsService = new Service();

export default UbiAppointmentsService;