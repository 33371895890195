import { useState, useEffect, useRef } from 'react';
import { TextField, Grid } from '@mui/material';
import InputFieldsContext from '../../context/input_fields/context';

export function EmailField({ field, size }) {

    const emailValue = useRef('')

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const fieldName = field.field_state_name;

        const value = emailValue.current.value

        if (validateEmail(value) && field.required){
            updateInputFields({[fieldName]: value});
            updateErrors({[fieldName]: false});
        }

        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const checkEmailValue = (value, fieldName) => {
        if (!validateEmail(value) && field.required) {
            updateErrors({[fieldName]: true});
            setShowError(true);
        }
        if (validateEmail(value) && field.required) {
            updateErrors({[fieldName]: false});
            setShowError(false);
        }
    }

    const handleChange = (elem) => {
        const { value } = elem.target;
        const fieldName = field.field_state_name;
        
        checkEmailValue(value, fieldName)

        updateInputFields({[fieldName]: value});
    };

    const validateEmail = (email) => {

        let email_reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        if (email_reg.test(email) === false) {
            return false
        }
        return true
    }

    return (
      <Grid item xs={12} md={size}>
          <TextField 
                fullWidth
                error={showError}
                helperText={showError && "El valor de este campo no es un correo electrónico"}
                required={field.required}
                className="margin-bottom-text-field border-radius-30"
                name="email"
                id="outlined-basic" 
                value={formState.inputs[field.field_state_name] || ''}
                onChange={handleChange}
                label={field.field_name}
                inputRef={emailValue}
                variant="outlined" 
          />
      </Grid>
    )
}