import { TextField, Grid, ToggleButton } from '@mui/material';
import UbiAppointmentsAPI from '../../api/api';
import { StyledToggleButtonGroup } from '../MUIComponents/StyledToggleButtonGroup';
import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import InputFieldsContext from '../../context/input_fields/context';

export function DateTimeField({ field }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [buttons, setButtons] = useState([]);
    const [buttonsSelect, setButtonsSelect] = useState();
    const [date, setDate] = useState();

    // Incluimos el campo a la lista de campos con posibilida de errores
    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    // Se obtiene los bloques de horas disponibles para cierta pregunta por medio del endpoint
    const getAvailableHours = async (date) => {
        const {status, response, errors} = await UbiAppointmentsAPI.consumeEndpoint('available-hours',null,null,null,{
            question: field.field_state_name, 
            day: date,
        });
        if (status === 200){
            setButtons(response.data)
        } else {
            console.log(status);
        }
    }

    const handleChange = (value) => {
        const fieldName = field.field_state_name;
        const year = value.$y.toString();
        const month = value.$M+1<10 ? "0"+(value.$M+1).toString() : (value.$M+1).toString();
        const day = value.$D<10 ? "0"+value.$D.toString() : value.$D.toString();
        const date = year+"-"+month+"-"+day;
        
        getAvailableHours(date);

        if (field.required) {
            updateErrors({[fieldName]: true});
        }
        setButtonsSelect();
        setDate(date);
        updateInputFields({[fieldName]: date});
    };

    const handleButtonSelect = (event, value) => {
        const fieldName = field.field_state_name;

        setButtonsSelect(value);
        updateInputFields({[fieldName]: date+" "+dayjs('1/1/1 '+value).format("HH:mm:00")}); // Format hour to 24 hour
        if (field.required) {
            updateErrors({[fieldName]: false});
        }
    }
      

    return (
      <Grid item xs={12} md={12}>
        <h4 className="date-label">{field.field_name}{field.required && " *"}</h4>
        <Grid container>
            <Grid item xs={12} md={6} className="date-picker-style">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <StaticDatePicker
                        disablePast
                        displayStaticWrapperAs="desktop"
                        id="outlined-basic"
                        value={dayjs(formState.inputs[field.field_state_name] || '')}
                        onChange={handleChange}
                        renderInput={(params) => <TextField required={field.required} {...params} />}
                    />
                </LocalizationProvider>
            </Grid>

            <Grid item xs={12} md={6}>
                <h4 className="time-label">Elija la hora de la cita</h4>
                <StyledToggleButtonGroup
                    size="small"
                    orientation="vertical"
                    exclusive
                    value={buttonsSelect}
                    onChange={handleButtonSelect}
                    className='styled-toggle-buton'
                    aria-label="text alignment"
                >
                    {buttons.map((text, i) => 
                    <ToggleButton value={text} key={i} aria-label="left aligned">
                        {text}
                    </ToggleButton>
                    )}
                </StyledToggleButtonGroup>
            </Grid>
        </Grid>
      </Grid>
    )
}