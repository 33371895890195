
const updateInputFields = (dispatch) => (inputFields) => {

    dispatch({
        type: "updateInputs",
        payload: inputFields
    })

}

const updateErrors = (dispatch) => (errors) => {

    dispatch({
        type: "updateErrors",
        payload: errors
    })

}

const setSpecialistQuestion = (dispatch) => (specialistQuestion) => {
    dispatch({
        type: "setSpecialistQuestion",
        payload: specialistQuestion
    })
}


const setFirstTimeVisitor = (dispatch) => (firstTimeVisitor) => {
    dispatch({
        type: "setFirstTimeVisitor",
        payload: firstTimeVisitor
    })
}

const setDisplayNewTextField = (dispatch) => (displayNewTextField) => {
    dispatch({
        type: "setDisplayNewTextField",
        payload: displayNewTextField
    })
}

const resetState = (dispatch) => () => {

    dispatch({
        type: "resetState",
        payload: ""
    })
}

export default {
    updateInputFields,
    updateErrors,
    setSpecialistQuestion,
    setFirstTimeVisitor,
    setDisplayNewTextField,
    resetState
}