import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './static/css/variables.css'
import './static/css/index.css';
import { App } from './App';
import { createBrowserHistory } from 'history';

const history = createBrowserHistory();

const root = ReactDOM.createRoot(document.getElementById('root'));

if (root) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
	const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];
	if (path) {
		history.replace(path);
	}
    root.render(
        <BrowserRouter>
            <App />
        </BrowserRouter>
    );
}