import React, { useState } from 'react'
import UbiAppointmentsAPI from '../api/api';
import { AddressField } from './Fields';
import { FORM_IMAGE_URL, DOMAIN } from '../settings/config';
import { Button, Grid } from '@mui/material';
import InputFieldsContext from '../context/input_fields/context';

export function FormConfirmation({form, marks}) {

    const { state:formState } = InputFieldsContext.UseContext();
    const [requestSended, setRequestSended] = useState(false);

    const sendResponse = async () => {
        const {status, response, errors} = await UbiAppointmentsAPI.consumeEndpoint('send-response', {'inputs':formState.inputs});
        if (status === 200){
            setRequestSended(true);
        } else {
            console.log(status);
        }
    }

    const renderField = (field, key, fields) => {

        let field_size = (fields.length - 1 === key && fields.length%2 !== 0) ? 12 : 6

        if (field.field_type === "AddressField") {
            return (
                <Grid key={key} item xs={12} md={12} className='margin-0'>
                    <AddressField field={field} field_location={formState.inputs[field.field_state_name]} key={key}/>
                </Grid>
            )
        }

        else if (field.field_type === "DateTimeField") {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            const date = new Date(formState.inputs[field.field_state_name]);

            if (field.is_doctor_appointment_question && field.field_state_name !== formState.specialistQuestion){
                return null
            }

            if (field.is_tentative_date && formState.inputs[field.field_state_name] === undefined){
                return null
            }

            if ((field.is_doctor_appointment_question && field.field_state_name === formState.specialistQuestion)){
                field_size = 12
            }

            return (
                <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                    <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{date.toLocaleDateString("es-ES", options)}</span></h3>
                </Grid>
            )
        }

        else if (field.field_type === "DateField") {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const date = new Date(formState.inputs[field.field_state_name]);

            return (
                <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                    <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{date.toLocaleDateString("es-ES", options)}</span></h3>
                </Grid>
            )
        }

        else if (field.field_type === "ChoiceField"){
            if (formState.inputs[field.field_state_name]) {
                field_size = (field.is_doctor_selection) ? 12 : 6
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formState.inputs[field.field_state_name]}</span></h3>
                    </Grid>
                )
            } else {
                return null;
            }
        }

        else if (field.field_type === "BooleanField"){
            if (formState.inputs[field.field_state_name]){
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formState.inputs[field.field_state_name]}</span></h3>
                    </Grid>
                )
            }
        }

        else {
            if (formState.inputs[field.field_state_name] !== undefined){
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formState.inputs[field.field_state_name]}</span></h3>
                    </Grid>
                )
            } else {
                return null;
            }
        }  
    }

    return (
        <>
            <Grid container className="form-navbar" spacing={5}>
                <Grid item xs={10} md={10}>
                    <img
                        src={FORM_IMAGE_URL}
                        alt={'Imagen'}
                        loading="lazy"
                        className='form-image'
                    />
                </Grid>
            </Grid>

            <div className="custom-form">

                <Grid container spacing={0} className='container-grid'>
                    <Grid item xs={0} md={2} className='form-steps-bg form-steps'>
                        {marks.map((mark, i, marks) =>
                            <div key={i} className='step-label'>
                                <div className='circle-2'>
                                    {(marks.length === i+1) ? 
                                        <div className='circle circle-selected'>
                                            {marks[i]["value"]+1}
                                        </div>
                                    :
                                        <div className='circle'>
                                            {mark["value"]+1}
                                        </div>
                                    }
                                </div>
                                {mark["label"]}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} md={10} className='form-content'>
                        <h1 className="step-title">RESUMEN</h1>
                            {form.map((step, i) => { return(
                                <div key={i}>
                                    <Grid container spacing={0} className='container-grid'>
                                        <Grid item xs={0} md={1}>
                                            <div className='circle circle-selected resumen-circle'>
                                                {i+1}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={11}>
                                            <h2 className="confirmation-subtitle">Paso {i+1}: {step.step_name}</h2>
                                            <hr className='subtitle'/>
                                            <Grid container spacing={0} className='container-grid'>
                                                {step.fields.map((field, key, fields) => renderField(field, key, fields))}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </div>
                            )})}
                        <hr/>
                        <div className='confirmation-control'>
                            {!requestSended ?
                                <Button 
                                    variant="contained"
                                    className='send-request-button form-button-bg' 
                                    onClick={sendResponse}
                                >
                                    Enviar petición
                                </Button> 
                            :   
                                <div >
                                    <h3>
                                        Su solicitud ha sido reservada, recibirá un correo con la aprobación del administrador una vez hecha la revisión manual
                                    </h3>
                                    <div className='return-button-container'>
                                        <Button 
                                            variant="contained"
                                            className='send-request-button form-button-bg return-button' 
                                            onClick={() => {window.location.href = DOMAIN.replace("appointments", "landing")}}
                                        >
                                            Volver a IMOC
                                        </Button> 
                                    </div>
                                </div>
                            }
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
