import React, { useState, useEffect } from 'react'
import UbiAppointmentsAPI from '../api/api';
import { AddressField } from './Fields';
import { FORM_IMAGE_URL } from '../settings/config';
import { Button, Grid } from '@mui/material';
import { useParams } from "react-router-dom";
import html2canvas from 'html2canvas'
import { jsPDF } from "jspdf";

export function FormConfirmed({form}) {

    const { appointment } = useParams();
    const [formResponse, setFormResponse] = useState();
    const [hideButton, setHideButton] = useState(false);

    // Se obtiene la data resultante de la llamada al endpoint
    const getFormResponse = async () => {
        const {status, response, errors} = await UbiAppointmentsAPI.consumeEndpoint('get-form-response',null,null,null,{ appointment: appointment });
        if (status === 200){
            setFormResponse(response.data);
        } else {
            console.log(status);
        }
    }

    // Al cargar, ejecutar función
    useEffect(() => {
        getFormResponse();
    }, []);

    // Tomar screenshot de pantalla y devolver como pdf
    useEffect(() => {
        if (hideButton) {
            html2canvas(document.querySelector(`#root`), {allowTaint: true, useCORS: true}).then(canvas => {
                let dataURL = canvas.toDataURL('image/png');

                if (canvas.width < 1100) {
                    var orientation = "p";
                }
                else {
                    var orientation = "l";
                }

                const pdf = new jsPDF(orientation,'pt', [canvas.width, canvas.height]);
                
                var pdfWidth = pdf.internal.pageSize.getWidth();
                var pdfHeight = pdf.internal.pageSize.getHeight();

                pdf.addImage(dataURL, 'PNG',0,0,pdfWidth,pdfHeight);
                pdf.save(`pdf.pdf`);
            });
            setHideButton(false);
        }
    }, [hideButton]);

    const renderField = (field, key, fields) => {

        let field_size = (fields.length - 1 === key && fields.length%2 !== 0) ? 12 : 6

        if (field.field_type === "AddressField") {
            return (
                <Grid key={key} item xs={12} md={12} className='margin-0'>
                    <AddressField field={field} field_location={formResponse[field.field_state_name]} key={key}/>
                </Grid>
            )
        }

        else if (field.field_type === "DateTimeField") {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
            const date = new Date(formResponse[field.field_state_name]);

            if (field.is_doctor_appointment_question && formResponse[field.field_state_name] === undefined){
                return null
            }

            if (field.is_tentative_date && formResponse[field.field_state_name] === undefined){
                return null
            }

            if ((field.is_doctor_appointment_question && formResponse[field.field_state_name] !== undefined)){
                field_size = 12
            }

            if (field.is_tentative_date){
                return null
            }

            return (
                <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                    <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{date.toLocaleDateString("es-ES", options)}</span></h3>
                </Grid>
            )
        }

        else if (field.field_type === "DateField") {
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            const date = new Date(formResponse[field.field_state_name]);

            return (
                <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                    <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{date.toLocaleDateString("es-ES", options)}</span></h3>
                </Grid>
            )
        }

        else if (field.field_type == "ChoiceField"){
            if (formResponse[field.field_state_name]) {
                field_size = (field.is_doctor_selection) ? 12 : 6
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formResponse[field.field_state_name]}</span></h3>
                    </Grid>
                )
            } else {
                return null;
            }
        }

        else if (field.field_type == "BooleanField"){
            if (formResponse[field.field_state_name]){
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formResponse[field.field_state_name]}</span></h3>
                    </Grid>
                )
            }
        }

        else {
            if (formResponse[field.field_state_name] !== undefined){
                return (
                    <Grid key={key} item xs={12} md={field_size} className='margin-0'>
                        <h3 key={key} className='margin-h3-resumen'>{field.field_name}: <span className='font-weight-normal'>{formResponse[field.field_state_name]}</span></h3>
                    </Grid>
                )
            } else {
                return null;
            }
        }
    }

    return (
        <>
            <Grid container className="form-navbar" spacing={5}>
                <Grid item xs={10} md={10}>
                    <img
                        src={FORM_IMAGE_URL}
                        alt={'Imagen'}
                        loading="lazy"
                        className='form-image'
                    />
                </Grid>
            </Grid>

            <div className="custom-form form-content">
                <h1 className="step-title">CITA CONFIRMADA</h1>
                    {form.map((step, i) => { return(
                        <div key={i}>
                            <Grid container spacing={0} className='container-grid'>
                                <Grid item xs={0} md={1}>
                                    <div className='circle circle-selected resumen-circle'>
                                        {i+1}
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={11}>
                                    <h2 className="confirmation-subtitle">Paso {i+1}: {step.step_name}</h2>
                                    <hr className='subtitle'/>
                                    <Grid container spacing={0} className='container-grid'>
                                        {step.fields.map((field, key, fields) => renderField(field, key, fields))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    )})}
                <hr/>
                <div className='confirmation-control'>
                    <h3 className=''>
                        Se ha enviado una invitación de calendario a su dirección de correo electrónico.
                    </h3>
                </div>
                <div className='confirmation-control'>
                    {!hideButton &&
                        <Button 
                            variant="contained"
                            className='pdf-button form-button-bg'
                            onClick={()=>setHideButton(true)}
                        >
                            Descargar en formato PDF
                        </Button>
                    }
                </div>
            </div>
        </>
    )
}
