import { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import InputFieldsContext from '../../context/input_fields/context';

export function PhoneField({ field, size }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const handleChange = (phone) => {
        const fieldName = field.field_state_name;

        if (field.required && phone === '') {
            updateErrors({[fieldName]: true});
            setShowError(true);
        }
        if (field.required && phone !== '') {
            updateErrors({[fieldName]: false});
            setShowError(false);
        }

        updateInputFields({[fieldName]: phone});
    };

    return (
        <Grid item xs={12} md={size}>
            <PhoneInput
                className="margin-bottom-text-field phone-field"
                country={'ve'}
                isValid={!showError}
                value={formState.inputs[field.field_state_name] || ''}
                onChange={handleChange}
                specialLabel={field.required ? field.field_name+" *" : field.field_name}
                inputProps={{
                    name: 'phone',
                    required: field.required,
                    label: field.field_name
                }}
            />
        </Grid>
    )
}