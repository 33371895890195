import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Grid, Autocomplete, TextField } from '@mui/material';
import InputFieldsContext from '../../context/input_fields/context';

export function ChoiceField({ field, size, pre_selected }) {

    const { state:formState, updateInputFields, updateErrors, setSpecialistQuestion, setDisplayNewTextField} = InputFieldsContext.UseContext();

    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }

        if (field.is_doctor_selection && pre_selected !== undefined){
            let selected_doctor = field.choices.filter((doctor) => { return doctor.id == pre_selected})[0]
            handleChange(null, selected_doctor)
        }
    }, [])

    const handleChange = (elem, complete_elem) => {
        const value = complete_elem.name
        const fieldName = field.field_state_name;
        
        updateInputFields({[fieldName]: value});
        if (field.required) {
            updateErrors({[fieldName]: false});
        }

        if (field.is_doctor_selection){
            const specialistQuestion = complete_elem.doctor_question
            setSpecialistQuestion(specialistQuestion);
        }

        if (value.toLowerCase().indexOf('otro') !== -1){
            setDisplayNewTextField(true)
        } else {
            setDisplayNewTextField(false)
        }
    };

    return (
        <Grid item xs={12} md={size}>
            <FormControl 
                fullWidth
            >
                <InputLabel id={`${formState.inputs[field.field_state_name]}-select-label`}></InputLabel>
                <Autocomplete
                    required={field.required}
                    options={field.choices}
                    className="margin-bottom-text-field border-radius-30-fieldset"
                    id="outlined-basic"
                    value={formState.inputs[field.field_state_name] || ""}
                    label={field.field_name}
                    onChange={handleChange}
                    renderInput={(params) => <TextField {...params} label={field.field_name} />}
                    renderOption={(props, option) => (
                        <li {...props} key={option.id} value={option.name} doctor_q={option.doctor_question}>{option.name}</li>
                    )}
                    isOptionEqualToValue={(option, value) => {
                        return (option.name === value)
                    }}
                    getOptionLabel={(option) => {
                        return option.name ? option.name : option
                    }}
                />
            </FormControl>
        </Grid>
    )
}