import { useState, useEffect, useRef } from 'react';
import { TextField, Grid } from '@mui/material';
import InputFieldsContext from '../../context/input_fields/context';

export function StringField({ field, size, hide_field }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [showError, setShowError] = useState(false);

    const stringValue = useRef('')

    // Incluimos el campo a la lista de campos con posibilida de errores
    useEffect(() => {
        const fieldName = field.field_state_name;
        if (stringValue.current.value !== ''){
            updateInputFields({[fieldName]: stringValue.current.value});
            updateErrors({[fieldName]: false});
        }
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const handleChange = (elem) => {
        const { value } = elem.target;
        const fieldName = field.field_state_name;

        // Verificación de campo requerido
        if (field.required && value === '') {
            updateErrors({[fieldName]: true});
            setShowError(true);
        }
        if (field.required && value !== '') {
            updateErrors({[fieldName]: false});
            setShowError(false);
        }

        updateInputFields({[fieldName]: value});
    };

    if (hide_field){
        return (<Grid item xs={12} md={size} style={{display: formState.displayNewTextField ? 'block' : 'none'}}>
            <TextField 
                inputRef={stringValue}
                fullWidth
                error={showError}
                helperText={showError && "Este campo es requerido"}
                required={field.required}
                className="margin-bottom-text-field border-radius-30"
                id="outlined-basic" 
                value={formState.inputs[field.field_state_name] || ''}
                onChange={handleChange}
                label={field.field_name}
                variant="outlined" 
            />
        </Grid>)
    } else {
        return (<Grid item xs={12} md={size}>
            <TextField 
                inputRef={stringValue}
                fullWidth
                error={showError}
                helperText={showError && "Este campo es requerido"}
                required={field.required}
                className="margin-bottom-text-field border-radius-30"
                id="outlined-basic" 
                value={formState.inputs[field.field_state_name] || ''}
                onChange={handleChange}
                label={field.field_name}
                variant="outlined" 
            />
        </Grid>)
    }
}