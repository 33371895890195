import { useState, useEffect } from 'react';
import { TextField, Grid } from '@mui/material';
import InputFieldsContext from '../../context/input_fields/context';

export function FloatField({ field, size }) {

    const { state:formState, updateInputFields, updateErrors } = InputFieldsContext.UseContext();
    const [showError, setShowError] = useState(false);

    useEffect(() => {
        const fieldName = field.field_state_name;
        if (field.required && formState.inputs[field.field_state_name] === undefined) {
            updateErrors({[fieldName]: true});
        }
    }, [])

    const handleChange = (elem) => {
        const { value } = elem.target;
        const fieldName = field.field_state_name;

        if (field.required && value === '') {
            updateErrors({[fieldName]: true});
            setShowError(true);
        }
        if (field.required && value !== '') {
            updateErrors({[fieldName]: false});
            setShowError(false);
        }
        
        updateInputFields({[fieldName]: Number(value)});
    };

    return (
      <Grid item xs={12} md={size}>
          <TextField 
              fullWidth
              required={field.required}
              error={showError}
              helperText={showError && "Este campo es requerido"}
              className="margin-bottom-text-field border-radius-30"
              type="number"
              id="outlined-basic" 
              value={formState.inputs[field.field_state_name] || ''}
              onChange={handleChange}
              inputProps={{step: "0.01"}}
              label={field.field_name}
              variant="outlined" 
          />
      </Grid>
    )
}