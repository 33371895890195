import React, { useState, useEffect } from 'react';
import { FORM_IMAGE_URL } from '../settings/config';
import { useNavigate } from "react-router-dom";
import { Button, Grid } from '@mui/material';
import { StringField, EmailField, PhoneField, BooleanField, IntegerField, FloatField, ChoiceField, AddressField, DateTimeField, DateField } from './Fields';
import 'react-phone-input-2/lib/material.css';
import InputFieldsContext from '../context/input_fields/context';
import { useParams } from "react-router-dom";

var BreakException = {};

export function FormStep({ step_title, fields, curr_step, next_step, marks }) {

    const navigate = useNavigate();

    const { state:formState, resetState } = InputFieldsContext.UseContext();

    const [nextDisabled, setNextDisabled] = useState(true);

    const { test } = useParams();

    // Redirecciona al primer paso al recargar la página
    useEffect(() => {
        if (test){
            navigate(`/step_0/${test}`);
        } else {
            navigate("/step_0/")
        }


        
    }, []);

    useEffect(() =>{
        if (curr_step === 0){
            resetState()
        }
    }, [])

    // Revisa si todos los campos del paso actual han sido llenados, si es así puede seguir con el formulario
    useEffect(() => {
        let hasErrors = false;
        try {
            fields.forEach((elem) => {
                if (formState.errors[elem.field_state_name]) {
                    hasErrors = true;
                    throw BreakException;
                }
            });
        } catch (e) {
            if (e !== BreakException) throw e;
        }

        if (nextDisabled !== hasErrors)
            setNextDisabled(hasErrors);
            
    }, [formState.errors])

    const handleNextStep = () => {
        if (next_step === -1) {
            navigate("/confirmation");
        }
        else {
            if (test){
                navigate(`/step_${next_step}/${test}`);
            } else {
                navigate(`/step_${next_step}`);
            }
        }
    }

    const handlePrevStep = () => {
        if (curr_step !== 0) {
            if (test){
                window.location.href = window.location.href.split("step_")[0] + "step_0/" + window.location.href.split("step_")[1].split("/")[1]
            } else {
                window.location.href = window.location.href.split("step_")[0]
            }
        }
    }

    // Renderiza el componente indicado para cada field
    const renderField = (field, i, fields) => {

        let field_size = (fields.length - 1 === i && fields.length%2 !== 0) ? 12 : 6

        if (field.is_doctor_appointment_question && (field.field_state_name !== formState.specialistQuestion)){
            return null
        }

        if (field.is_doctor_appointment_question && field.field_state_name === formState.specialistQuestion){
            field.required = true;
        }

        if (fields.filter((one_field) => {return one_field.is_doctor_appointment_question}).length > 0 && field.is_tentative_date){
            if (formState.specialistQuestion === ""){
                field.required = true;
            } else {
                field.required = false;
                return null;
            }
        }

        if (field.is_doctor_selection && formState.firstTimeVisitor){
            field.required = false;
            return null
        }

        if (!field.is_doctor_selection && formState.firstTimeVisitor && field.field_type === "ChoiceField"){
            field.required = true;
            field_size = 12;
        }

        if (fields.filter((one_field) => {return one_field.is_doctor_selection}).length > 0 && formState.firstTimeVisitor && field.field_type === "StringField"){
            field_size = 12;
            return <StringField field={field} key={i} size={field_size} hide_field={true}/>
        }

        if (fields.filter((one_field) => {return one_field.is_doctor_selection}).length > 0 && !formState.firstTimeVisitor && !field.is_doctor_selection){
            return null
        }

        if (field.is_doctor_selection && !formState.firstTimeVisitor){
            field_size = 12
        }

        if (field.field_type === "StringField") {
            return <StringField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "EmailField") {
            return <EmailField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "PhoneField") {
            return <PhoneField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "BooleanField") {
            return <BooleanField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "IntegerField") {
            return <IntegerField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "FloatField") {
            return <FloatField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "ChoiceField") {
            if (test){
                return <ChoiceField field={field} key={i} size={field_size} pre_selected={test}/>
            } else {
                return <ChoiceField field={field} key={i} size={field_size}/>
            }
            
        }

        else if (field.field_type === "AddressField") {
            return <AddressField field={field} field_location={field.location} key={i}/>
        }

        else if (field.field_type === "DateField") {
            return <DateField field={field} key={i} size={field_size}/>
        }

        else if (field.field_type === "DateTimeField") {
            return <DateTimeField field={field} key={i}/>
        }
    }


    return (
        <>
            <Grid container className="form-navbar" spacing={5}>
                <Grid item xs={10} md={10}>
                    <img
                        src={FORM_IMAGE_URL}
                        alt={'Imagen'}
                        loading="lazy"
                        className='form-image'
                    />
                </Grid>
            </Grid>

            <div className="custom-form">
                
                <Grid container spacing={0} className='container-grid container-grid-max-width'>
                    <Grid item xs={0} md={2} className='form-steps-bg form-steps'>
                        {marks.map((mark, i) =>
                            <div key={i} className='step-label'>
                                <div className='circle-2'>
                                    {(curr_step === mark["value"]) ? 
                                        <div className='circle circle-selected'>
                                            {mark["value"]+1}
                                        </div>
                                    :
                                        <div className='circle'>
                                            {mark["value"]+1}
                                        </div>
                                    }
                                </div>
                                {mark["label"]}
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={12} md={10} className='form-content'>
                        <h1 className="step-title">{step_title.toUpperCase()}</h1>
                        <Grid container spacing={2} className='container-grid'>
                            {fields.map((field, i, fields) => renderField(field, i, fields))}
                        </Grid>

                        <Grid container className='form-buttons' spacing={2}>
                            <Grid item xs={0} md={6} className="padding-none-mobile"/>
                            <Grid item xs={6} md={3}>
                                {(curr_step !== 0) &&
                                    <Button 
                                        variant="outlined" 
                                        className='form-button-width form-border-bg' 
                                        disabled={(curr_step === 0)}
                                        onClick={handlePrevStep}
                                    >
                                        Atrás
                                    </Button>
                                }
                            </Grid>
                            <Grid item xs={6} md={3}>
                                <Button 
                                    variant="contained"
                                    disabled={nextDisabled}
                                    className='form-button-width form-button-bg' 
                                    onClick={handleNextStep}
                                >
                                    {(next_step === -1) ? "Repaso" : "Siguiente"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
