import React, { useReducer,useContext,createContext } from 'react';

export const makeContext = (reducer,actions={}) => {
    
    const Context = createContext();

    const Provider = ({ children,initialState={} }) => {

        const [state,dispatch] = useReducer(reducer,initialState);
    
        const boundActions = {};

        for(let key in actions){
            boundActions[key] = actions[key](dispatch);
        }
        
        return (
            <Context.Provider value={{state, ...boundActions}}>{children}</Context.Provider>
        )
    
    }

    const UseContext = () => useContext(Context)

    return {Context,Provider,UseContext}

}
