export const initialState = {
    inputs: {},
    errors: {},
    specialistQuestion: "",
    firstTimeVisitor: false,
    displayNewTextField: false

}

export const InputFieldsReducer = (state = initialState, action)=>{

    if(action.type === "updateInputs"){

        return {
            ...state,
            inputs: {...state.inputs, ...action.payload}
        }        
    }

    if(action.type === "updateErrors"){

        return {
            ...state,
            errors: {...state.errors, ...action.payload}
        }        
    }

    if(action.type === "setSpecialistQuestion"){

        return {
            ...state,
            specialistQuestion: action.payload
        }        
    }

    if(action.type === "setFirstTimeVisitor"){

        return {
            ...state,
            firstTimeVisitor: action.payload
        }        
    }

    if(action.type === "setDisplayNewTextField"){

        return {
            ...state,
            displayNewTextField: action.payload
        }        
    }

    if(action.type === "resetState"){

        return {
            inputs: {},
            errors: {},
            specialistQuestion: "",
            firstTimeVisitor: false,
            displayNewTextField: false
        
        }
            
    }
    
    return state
}