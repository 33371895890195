import React from 'react'

export function Error404() {
  return (
    <div className='m-auto'>
      <h1 style={{fontSize: 200, marginBottom: 0}}>404</h1>
      <h1>Oops! Page not found!</h1>
    </div>
  );
}
