import React, { useState, useEffect } from "react";
import UbiAppointmentsAPI from "./api/api";
import { Route, Routes, Navigate } from 'react-router-dom';
// Context
import InputFieldsContext from './context/input_fields/context'
import { initialState as InputFieldsInitialState } from './context/input_fields/reducer';
// Components
import { FormStep } from "./components/FormStep";
import { Error404 } from "./components/Errors/Error404";
import { FormConfirmation } from "./components/FormConfirmation";
import { FormConfirmed } from "./components/FormConfirmed";
import { useNavigate } from "react-router-dom";

export function App() {
    const navigate = useNavigate()
    const [formWizard, setFormWizard] = useState([]);
    const [fieldsStep0, setFieldsStep0] = useState([])
    const [titleStep0, setTitleStep0] = useState('')
    
    // Se obtiene la data resultante de la llamada al endpoint
    const getForm = async () => {
        const {status, response, errors} = await UbiAppointmentsAPI.consumeEndpoint('get-form');
        if (status === 200){
            setTitleStep0(response.data[0].step_name)
            setFieldsStep0(response.data[0].fields)
            setFormWizard(response.data);
        } else {
            console.log(status);
        }
    }
    // Al cargar, ejecutar función
    useEffect(() => {
        getForm();
        
        // Advertencia al recargar la página
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
        
    }, []);

    // Carga las marcas del status bar del formulario
    const marks = []
    formWizard.map((step, i, steps) => {
        marks.push({
            value: i,
            label: step.step_name
        })
        if  (steps.length === i+1) {
            marks.push({
                value: i+1,
                label: "Resumen"
            })
        }
    })

    return (
        <InputFieldsContext.Provider initialState={InputFieldsInitialState}>
            <div className="form-page">
                <Routes>
                    <Route 
                        path={`/step_0/`} 
                        key={0} 
                        element={<FormStep 
                            step_title={titleStep0}
                            fields={fieldsStep0}
                            curr_step={0}
                            next_step={1}
                            marks={marks}
                        />}
                    />
                    <Route 
                        path={`/step_0/:test`} 
                        key={0} 
                        element={<FormStep 
                            step_title={titleStep0}
                            fields={fieldsStep0}
                            curr_step={0}
                            next_step={1}
                            marks={marks}
                        />}
                    /> 
                    {formWizard.map((step, i) => 
                        i != 0
                        ? <Route 
                            path={`/step_${i}/`} 
                            key={i} 
                            element={<FormStep 
                                step_title={step.step_name}
                                fields={step.fields}
                                curr_step={i}
                                next_step={(formWizard.length-1===i) ? -1 : i+1}
                                marks={marks}
                            />}
                           /> 
                        : null
                    )}
                    {formWizard.map((step, i) => 
                        i != 0
                        ? <Route 
                            path={`/step_${i}/:test`} 
                            key={i} 
                            element={<FormStep 
                                step_title={step.step_name}
                                fields={step.fields}
                                curr_step={i}
                                next_step={(formWizard.length-1===i) ? -1 : i+1}
                                marks={marks}
                            />}
                         />
                        : null
                    )}
                    <Route path='/confirmation' element={<FormConfirmation form={formWizard} marks={marks}/>} />
                    <Route path='/confirmed/'>
                        <Route path=':appointment' element={<FormConfirmed form={formWizard}/>} />
                    </Route>
                    <Route path='*' element={<Error404/>} />
                    <Route path="/" element= {
                        <Navigate to="/step_0/"/>
                    }/>
                </Routes>
                <div><p></p></div>
            </div>
        </InputFieldsContext.Provider>
    );
}