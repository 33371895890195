import { DOMAIN } from '../settings/config';
import axios from 'axios'
import UbiAppointmentsService from './endpoints/service';


class API {

    constructor() {
        this.baseURL = DOMAIN;
        this.api = axios.create({
            baseURL: this.baseURL,
            timeout: 10000
        })
    }


    buildRequestData(endpoint, data, headers, params) {

        var request_data = {
            method: endpoint.method,
            url: endpoint.relative_url,
        };

        if(data!=null){
            request_data["data"] = data;
        }

        if(headers!=null){
            request_data = {
                ...request_data,
                headers:headers
            };
        }

        if(params!=null){
            request_data = {
                ...request_data,
                params:params
            };
        }

        return request_data;
    }


    async consumeEndpoint(endpoint_id, data=null, headers=null, params=null, relative_data=null){

        // Obtener el endpoint que se va a utilizar
        var endpoint_class = UbiAppointmentsService.endpoints[endpoint_id];
        var endpoint = new endpoint_class();

        // Agregar data al url (Si lo necesita)
        if (relative_data!=null){
            endpoint.setRelativeUrlParameters(relative_data)
        }
        
        // Construir la solicitud
        var request_data = this.buildRequestData(endpoint, data, headers, params);

        const result = await this.api(request_data);
        return {
            status: result.status,
            response: result.data,
            errors: []
        }
    }
}

const UbiAppointmentsAPI = new API();

export default UbiAppointmentsAPI;